// Dependencies
import React from "react";
import styled from "styled-components";

interface CategoryHeaderProps {
  title: string;
}

const CategoryHeader: React.FC<CategoryHeaderProps> = ({ title }) => {
  return <TitleContainer>{title.toUpperCase()}</TitleContainer>;
};

export default CategoryHeader;

const TitleContainer = styled.div`
  position: relative;
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  font-weight: bold;
  padding-bottom: ${(props) => props.theme.space[3]}px;
  border-bottom: 4px solid ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
  }
`;
