// Dependencies
import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import Fade from "react-reveal";
import GraphImg from "graphcms-image";
import shuffle from "lodash/shuffle";

// Components
import SEO from "../components/seo";
import { Container } from "../pages/index";
import List from "../components/list";
import { StyledLink, StyledValue } from "../pages/arsenal/weapons_list";
import WeaponPower from "../components/weaponPower";
import { FilterSelectionContext } from "../components/filterSelectionContext";
import WeaponCarousel from "../components/weaponCarousel";
import PackageTile from "../components/packageTile";
import CategoryHeader from "../components/categoryHeader";
import Target from "../components/target";

interface cellsArray {
  header: string;
  body: string;
}

interface Props {
  pageContext: {
    id: number;
    name: string;
    category: string;
    gunPower: number;
    cells1: Array<cellsArray>;
    cells2: Array<cellsArray>;
    characteristics: string[];
    description: string[];
    image: Object;
    weapons: any;
    packages: any;
    featuredGuns: number[];
  };
}

const Weapon: React.FC<Props> = (props) => {
  const weapon = props.pageContext;
  const weapons = weapon.weapons;

  const RECOMMENDED_GUNS_MAX_LENGTH = 5;

  const shuffledSameCategoryWeapons = shuffle(
    weapons.filter(
      (e) =>
        e.category === `${weapon.category}` &&
        // prevent recommending the weapon from the page displayed
        e.weaponId !== weapon.id
    )
  );

  const featuredGuns = weapons.filter((e) =>
    weapon.featuredGuns.includes(e.id)
  );

  const recommendedGuns =
    featuredGuns.length < RECOMMENDED_GUNS_MAX_LENGTH
      ? [
          ...featuredGuns,
          ...shuffledSameCategoryWeapons.slice(
            0,
            RECOMMENDED_GUNS_MAX_LENGTH - featuredGuns.length
          ),
        ]
      : featuredGuns;

  const [, setActive] = useContext(FilterSelectionContext);

  const translateWeaponType = (type: string) => {
    switch (type) {
      case "Pistols":
        return "Pistolety";
      case "Rifles":
        return "Karabiny i karabinki";
      case "SubmachineGuns":
        return "Pistolety maszynowe";
      case "MachineGuns":
        return "Karabinki automatyczne";
      case "Shotguns":
        return "Strzelby gładkolufowe";
      case "Revolvers":
        return "Rewolwery";
      default:
        return "Broń";
    }
  };

  const packages = props.pageContext.packages;

  const filteredPackages = packages.filter((e) =>
    e.includedGuns.includes(weapon.id)
  );
  const firstFilteredPackages = filteredPackages.slice(0, 3);

  return (
    <Fragment>
      <Fade>
        <Container>
          <NavContainer>
            <StyledLink
              to={"/arsenal/"}
              onClick={() => setActive("")}
            >{`Arsenał \u2192 ${translateWeaponType(
              weapon.category
            )} \u2192`}</StyledLink>
            <StyledValue>{weapon.name}</StyledValue>
          </NavContainer>
          <WeaponInfoConteiner>
            <StyledTarget>
              <Target />
              <WeaponImage
                image={{
                  handle: weapon.image.handle,
                  width: 320,
                  height: 320 / (weapon.image.width / weapon.image.height),
                }}
                outerWrapperClassName="GraphCmsImgWrapper"
                blurryPlaceholder={false}
                withWebp
              ></WeaponImage>
            </StyledTarget>
            <InfoWrapper>
              <WeaponName>{weapon.name}</WeaponName>
              <FlexWrapper>
                {weapon.cells1 ? (
                  <InfoColumn>
                    {weapon.cells1.map((cell, index) => {
                      return (
                        <Cell key={index}>
                          <ContentHead>{cell.header}</ContentHead>
                          <ContentBody>{cell.body}</ContentBody>
                        </Cell>
                      );
                    })}
                  </InfoColumn>
                ) : null}
                {weapon.cells2 ? (
                  <InfoColumn>
                    {weapon.cells2.map((cell, index) => {
                      return (
                        <Cell key={index}>
                          <ContentHead>{cell.header}</ContentHead>
                          <ContentBody>{cell.body}</ContentBody>
                        </Cell>
                      );
                    })}
                    <ContentHead>Poziom trudności</ContentHead>
                    <WeaponPower power={weapon.gunPower} />
                  </InfoColumn>
                ) : null}
              </FlexWrapper>
            </InfoWrapper>
          </WeaponInfoConteiner>
          {weapon.characteristics ? (
            <CharacteristicsWrapper>
              <List
                details={weapon.characteristics}
                header={"Cechy charakterystyczne broni"}
              />
            </CharacteristicsWrapper>
          ) : null}
          {weapon.description && weapon.description.length > 0 ? (
            <DescriptionWrapper>
              <CategoryHeader title={"Opis"} />
              {weapon.description.map((p) => (
                <DescriptionParagraph>{p}</DescriptionParagraph>
              ))}
            </DescriptionWrapper>
          ) : null}
          {firstFilteredPackages.length > 0 && (
            <>
              <CategoryHeader title={"Broń dostępna w pakietach"} />
              <PackagesContainer>
                {firstFilteredPackages.map((course, index) => {
                  return (
                    <PackageTile
                      key={index}
                      name={course.title}
                      slug={`/pakiety/${course.slug}`}
                      thumbnail={course.image}
                      course={false}
                    />
                  );
                })}
              </PackagesContainer>
            </>
          )}
          {recommendedGuns.length > 0 && (
            <>
              <CategoryHeader title={"Zobacz również"} />
              <WeaponCarousel weaponsArray={recommendedGuns} />
            </>
          )}
        </Container>
      </Fade>
      <SEO />
    </Fragment>
  );
};

export default Weapon;

const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) => props.theme.space[5]}px;
`;

const CharacteristicsWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.space[7]}px;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.space[7]}px;
`;

const DescriptionParagraph = styled.p`
  margin: ${(props) => props.theme.space[4]}px 0;
`;

const WeaponName = styled.h3`
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  font-weight: 700;
  letter-spacing: 0.185rem;
  margin-bottom: ${(props) => props.theme.space[4]}px;
`;
const WeaponInfoConteiner = styled.div`
  display: flex;
  width: 100%;
  padding: ${(props) => props.theme.space[8]}px 0;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: row;
  }
`;
const WeaponImage = styled(GraphImg)`
  width: 100%;
`;

const StyledTarget = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 272px;
  width: 272px;
  border-radius: 50%;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    height: 345px;
    min-width: 345px;
    max-width: 345px;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    margin-bottom: 0;
  }

  .GraphCmsImgWrapper {
    display: flex;
    position: absolute !important;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    padding: 0 ${(props) => props.theme.space[5]}px;
  }
`;
const InfoColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const ContentHead = styled.p`
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  font-weight: 400;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[1]}px;
  }
`;

const ContentBody = styled.p`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  font-weight: 700;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[2]}px;
  }
`;

const Cell = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const PackagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;
